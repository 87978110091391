import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Helmet } from "react-helmet";
import { pageview } from '../utils/scripts';

class TermsPage extends React.Component {
  componentDidMount() {
    pageview();
  }

  render() {
    const paragraphClasses = 'text-lg md:text-xl text-gray-700 leading-8 mb-8';
    const containerClasses = 'container px-2 mx-auto flex flex-wrap items-center';
    const widthClasses = 'lg:w-1/2 md:w-2/3 flex flex-col md:mx-auto w-full';
    const headlineCLasses = 'text-center text-3xl md:text-4xl leading-9 font-bold text-gray-900 mb-8 font-headline';
    return (
      <Layout>
        <SEO title="Sidewall Club" />
        <section className="bg-white pt-6 px-4 md:px-0">
          <div className={containerClasses}>
            <div className={widthClasses}>
              <div name="termly-embed" data-id="25059628-4eeb-4da3-aa7d-7bf18d3a53bd" data-type="iframe" />
              <Helmet>
                <script>{`
                (function(d, s, id) { var js, tjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) return; js = d.createElement(s); js.id = id; js.src = "https://app.termly.io/embed-policy.min.js"; tjs.parentNode.insertBefore(js, tjs); }(document, 'script', 'termly-jssdk'));
              `}</script>
              </Helmet>

            </div>
          </div>
        </section>


      </Layout>
    );
  }
}

export default TermsPage;
